<template>
    <SmartTable ref="ricercaSodalizi" url="/sportingclub/index" base_path="/sportingclub"
        :fixed_filters="(idannuality ? [{ name: 'idannuality', filter: idannualitycalculated }] : null)">
        <template v-slot:before-filters>
            <div>
                <form>
                    <div class="form-group">
                        <label for="idannuality">Annualità:</label>
                        <button class="btn btn-ssmm btn-primary ml-2" type="button"
                            @click.prevent="selectAll()">Seleziona Tutti</button>
                        <button class="btn btn-ssmm btn-primary ml-2" type="button"
                            @click.prevent="deselectAll()">Deseleziona Tutti</button>
                        <multiselect style="width: 100%;" v-model="idannuality" :options="annualities" :multiple="true"
                            :close-on-select="false" :clear-on-select="false" placeholder="Seleziona un'annualità"
                            label="label" track-by="id" :allow-empty="true" selectLabel="" selectedLabel=""
                            deselectLabel="" showLabels="">
                            <template v-slot:noOptions>
                                Nessuna annualità disponibile
                            </template>
                        </multiselect>
                    </div>
                </form>
            </div>
        </template>

        <template v-slot:th-coniaffiliationsendedflag="slotProps">
            <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                <span class="text-muted text-white">&nbsp;</span>
            </th>
        </template>

        <template v-slot:th-rsaffiliationsendedflag="slotProps">
            <th class="d-none"></th>
        </template>

        <template v-slot:td-rsaffiliationsendedflag="slotProps">
            <th class="d-none"></th>
        </template>

        <template v-slot:th-hasenrolledusersflag="slotProps">
            <th class="d-none"></th>
        </template>

        <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
            <th class="d-none"></th>
        </template>

        <template v-slot:th-cipflag="slotProps">
            <th class="d-none"></th>
        </template>

        <template v-slot:th-idrssubscriptionstatus="slotProps">
            <th class="d-none"></th>
        </template>

        <template v-slot:td-coniaffiliationsendedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div
                    class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                    <span v-if="slotProps.values.item.newaffiliationflag === 'Si'" class="badge pill bg-info mb-1"
                        style="font-size: 0.9em;"><i class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                    <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'Si'"
                        class="badge pill bg-primary mb-1" style="font-size: 0.9em;"><i
                            class="fas fa-satellite-dish text-white"></i> INVIATO AL CONI</span>

                    <span v-if="slotProps.values.item.idrssubscriptionstatus" class="badge pill bg-primary mb-1" :class="{
                        'bg-primary': [
                            'In completamento',
                            'In convalida',
                            'Integrazioni inviate',
                            'Integrazioni inviate (dopo istruttoria)',
                            'Presa in carico da un operatore',
                            'Presa in carico da un operatore (dopo istruttoria)',
                            'Convalidata in istruttoria',
                        ].includes(slotProps.values.item.idrssubscriptionstatus),

                        'bg-warning': [
                            'Richiesta di integrazione',
                            'Richiesta di integrazione (dopo istruttoria)',
                        ].includes(slotProps.values.item.idrssubscriptionstatus),

                        'bg-success': [
                            'Domanda accolta',
                        ].includes(slotProps.values.item.idrssubscriptionstatus),

                        'bg-danger': [
                            'Iscrizione sospesa',
                            'Iscrizione annullata',
                            'Domanda rifiutata',
                        ].includes(slotProps.values.item.idrssubscriptionstatus),
                    }"
                        style="font-size: 0.9em; text-transform: uppercase; text-align: left; text-wrap: wrap; max-width: 400px;"><i
                            class="fas fa-registered text-white"></i> <strong>ISCRIZIONE REGISTRO:</strong> {{
                                slotProps.values.item.idrssubscriptionstatus }}</span>

                    <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'" class="badge pill bg-success mb-1"
                        style="font-size: 0.9em;"><i class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                    <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'" class="badge pill bg-danger mb-1"
                        style="font-size: 0.9em;"><i class="fas fa-users-slash text-white"></i> NO TESSERATI
                        ATTIVI</span>

                    <span
                        v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                        class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                            class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                    <span
                        v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                        class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                            class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                    <span
                        v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                        class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                            class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                    <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                        style="font-size: 0.9em; background-color: #FF58F6;"><i
                            class="fab fa-accessible-icon text-white"></i> CIP</span>

                    <span
                        v-if="((slotProps.values.item.coniaffiliationflag === 'Si' && slotProps.values.item.coniaffiliationflagadmin === 'No') && (typeof slotProps.values.item.numsendedevents !== 'undefined') && slotProps.values.item.numsendedevents && (slotProps.values.item.numsendedevents > 0))"
                        class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVITÀ TRASMESSE <span
                            class="badge pill bg-white text-success m-0 px-2 py-1" style="font-size: 1.1em;">{{
                                slotProps.values.item.numsendedevents }}</span></span>

                    <span
                        v-if="((slotProps.values.item.coniaffiliationflag === 'Si' && slotProps.values.item.coniaffiliationflagadmin === 'No') && ((typeof slotProps.values.item.numsendedevents === 'undefined') || (!slotProps.values.item.numsendedevents) || (slotProps.values.item.numsendedevents <= 0)))"
                        class="badge pill bg-danger mb-1" style="font-size: 0.9em;">NESSUNA ATTIVITÀ TRASMESSA</span>
                </div>
            </td>
        </template>

        <template v-slot:td-idrssubscriptionstatus="slotProps">
            <td class="d-none"></td>
        </template>

        <template v-slot:td-hasenrolledusersflag="slotProps">
            <td class="d-none"></td>
        </template>

        <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
            <td class="d-none"></td>
        </template>

        <template v-slot:td-cipflag="slotProps">
            <td class="d-none"></td>
        </template>

        <template v-slot:td-affiliationdocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-statutedocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-taxcodedocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-identitycarddocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="d-flex align-items-center">
                    <div class="text-white">
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                            class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                        <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                            class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                    </div>
                </div>
            </td>
        </template>

        <template v-slot:td-idaffiliationstate="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div
                    class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                    <span
                        v-if="slotProps.values.item.deletedflag !== 'Si' && slotProps.values.item.newaffiliationflag === 'Si'"
                        class="badge pill bg-info mb-1" style="font-size: 0.9em;">PRIMA AFFILIAZIONE</span>

                    <span v-if="slotProps.values.item.deletedflag === 'Si'" class="badge pill bg-dark mb-1"
                        style="font-size: 0.9em;">DISATTIVATA</span>

                    <span
                        v-if="slotProps.values.item.deletedflag !== 'Si' && slotProps.values.item.idaffiliationstate === 'Accettata'"
                        class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVA</span>

                    <span
                        v-if="slotProps.values.item.deletedflag !== 'Si' && slotProps.values.item.idaffiliationstate === 'In Sospeso'"
                        class="badge pill bg-warning mb-1" style="font-size: 0.9em;">SOSPESA</span>

                    <span
                        v-if="slotProps.values.item.deletedflag !== 'Si' && slotProps.values.item.idaffiliationstate === 'Scaduta'"
                        class="badge pill bg-gray-600 mb-1" style="font-size: 0.9em;">SCADUTA</span>

                    <span
                        v-if="slotProps.values.item.deletedflag !== 'Si' && slotProps.values.item.idaffiliationstate === 'Rifiutata'"
                        class="badge pill bg-danger mb-1" style="font-size: 0.9em;">RIFIUTATA</span>
                </div>
            </td>
        </template>

        <template v-slot:td-numsendedevents="slotProps">
            <td class="d-none"></td>
        </template>
    </SmartTable>
</template>

<script>
import SmartTable from "@/view/components/SmartTable.vue";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    data() {
        return {
            idannuality: null,
            annualities: [],
            idannualitycalculated: [],
        };
    },

    created() {
        let url = '/sportingclub/get-annualities';
        ApiService.query(url)
            .then((response) => {
                this.annualities = response.data.annualities;

                let idannuality = response.data.idannuality;

                let label = '';
                for (let i in this.annualities) {
                    if (this.annualities[i].id === idannuality) {
                        label = this.annualities[i].label;
                    }
                }

                this.idannuality = { id: response.data.idannuality, label: label };
            })
            .catch((error) => {
                console.log(error);
            })
    },

    watch: {
        idannuality(value) {
            this.idannualitycalculated = [];

            for (let i in value) {
                if (i === 'id') {
                    this.idannualitycalculated.push(value.id);
                }
                else if (typeof value[i] === 'object') {
                    this.idannualitycalculated.push(value[i].id);
                }
            }

            let vm = this;
            setTimeout(function () {
                vm.$refs.ricercaSodalizi.refresh();
            }, 100);
        }
    },

    components: {
        SmartTable
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Affiliazioni", route: "/sportingclub/index" },
            { title: "Ricerca Sodalizi" }
        ]);
    },

    methods: {
        selectAll() {
            let options = this.annualities === null ? [] : this.annualities;
            let value = [];
            for (let i in options) {
                value.push(JSON.parse(JSON.stringify(options[i])));
            }

            this.idannuality = value;
        },

        deselectAll() {
            this.idannuality = [];
        },
    },
};
</script>
